/* Variables */
$color-1: black;
$color-2: white;
$color-3: #08950f;
$color-4: #ffbd00;

/* Styling */
.timeline,
.accordion-timeline {
  margin: 3em auto;
  position: relative;
  width: 100%;
  &:before {
    background-color: rgba($color-1, 0.2);
    content: "";
    margin-left: -1px;
    position: absolute;
    top: 0;
    left: 2em;
    width: 2px;
    height: 100%;
  }
}

.timeline-project {
  position: relative;

  &:hover {
    .timeline-project-icon {
      transform: rotate(-45deg);
      //   background-color: $color-3;
    }

    .timeline-project-inner {
      background-color: #f7f7f7;
    }

    .timeline-project-thumbnail {
      box-shadow: inset 40em 0 0 0 $color-4;
    }
  }
}

.timeline-project-detail {
  padding: 30px 0px;
  position: relative;
  top: -1.875em;
  left: 4em;
  width: 93%;

  h3 {
    font-size: 1.75em;
  }

  h4 {
    font-size: 1.2em;
    margin-bottom: 1.2em;
  }

  strong {
    font-weight: 700;
  }
}

.timeline-project-icon {
  transform: rotate(45deg);
  transition: transform 0.2s ease-in;
  background-color: $color-4;
  outline: 10px solid $color-2;
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 0;
  left: 2em;
  width: 1em;
  height: 1em;
}

.timeline-project-thumbnail {
  transition: box-shadow 0.5s ease-in 0.1s;
  color: $color-1;
  font-size: 0.75em;

  background-color: $color-4;
  box-shadow: inset 0 0 0 0em #e8fde9;
  display: inline-block;
  margin-bottom: 1.2em;
  padding: 0.25em 1em 0.2em 1em;
}

.accordion-timeline {
  position: relative;
  width: 100%;
  display: inline-block;
  margin-top: 20px;
  margin-left: 0px;
  &:before {
    top: 16px;
  }
  .accordion-item {
    position: relative;
    top: 0px;
    left: 4em;
    width: 93%;
    margin-top: 30px;
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 4px;
    .accordion-button {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      &:focus {
        box-shadow: none;
      }
    }
    &:not(:first-child) {
      margin-top: 60px;
    }
    &:hover {
      .timeline-project-icon {
        box-shadow: 0px 0px 0px 8px lighten($color-4, 30%);
      }

      .timeline-project-inner {
        background-color: #f7f7f7;
      }

      .timeline-project-thumbnail {
        box-shadow: inset 40em 0 0 0 $color-4;
      }
    }
    .accordion-button:not(.collapsed) {
      color: #191919;
      background-color: #ffbd0017;
    }
  }
  .timeline-project-icon {
    top: -33px;
    left: -29px;
    box-shadow: 0px 0px 0px 4px lighten($color-4, 30%);
    outline: none;
    border-radius: 50%;
    transition: box-shadow 200ms ease-in-out;
  }
  .timeline-project-thumbnail {
    position: absolute;
    margin-top: -30px;
    left: 0px;
    margin-bottom: 0px;
    font-weight: bold;
  }
}

.caf-header-action {
  position: absolute;
  right: 50px;
  top: 48%;
  z-index: 9;
  transform: translateY(-50%);
}
.accordion-header {
  position: relative;
}
