/* You can add global styles to this file, and also import other style files */
@charset "UTF-8";

body {
  margin: 0;
  font-size: 14px;
}

/* Word wrap */
td {
  word-break: break-word;
}

.scrollTopMargin {
  scroll-margin-top: 150px;
}

@media print {

  html,
  body {
    border: 1px solid white;
    height: 99%;
    page-break-after: avoid;
    page-break-before: avoid;
  }
}

#mis-dashboard-container {
  iframe {
    width: 100%;
    height: 78vh;
  }
}

#viewDiv {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}



/* Importing Bootstrap SCSS file. */
// @import "scss/fonts";
@import "~bootstrap/scss/functions";
@import "scss/variables";
@import "~bootstrap/scss/bootstrap";
/* === Layout === */
@import '~ngx-toastr/toastr';
@import "scss/layout";
@import "scss/block";
@import "scss/accordian";
@import "scss/stepper";
@import "scss/form";
@import "scss/navtabs";
@import "scss/navlinks";
@import "scss/status";
@import "scss/table";
@import "scss/timeline";
@import "scss/helper";
@import "scss/responsive";
@import "scss/print";