.section-bg {
  background: #fbfbfb;
}
.skp-gradient{
  background: linear-gradient(269.87deg, #084095 2.99%, #108E16 96.59%);
}
.fw-semi-bold {
  font-weight: $font-weight-semi-bold !important;
}
.btn-float {
  position: fixed;
  z-index: 2;
}
.float-bottom-end {
  right: 20px;
  bottom: 20px;
}
.bg-clipart {
  background-position: top right;
  background-repeat: no-repeat;
}
.cursor-pointer {
  cursor: pointer;
}

.rounded-12 {
  border-radius: 12px;
}
.rounded-8 {
  border-radius: 8px;
}

.fs-8 {
  font-size: 8px !important;
}
.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.ratio {
  img,
  video {
    object-fit: cover;
  }
}
.card-shadow {
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
}
.text-justify {
  text-align: justify;
}
.mw-95{
  max-width: 95%;
}
.bg-primary-light {
  background: #e6f3e7 !important;
}
.bg-warning-light {
  background:#ffe394 !important
}
.line-clamp {
  // display: -webkit-box;
  -webkit-box-orient: vertical;
  &--3{
    -webkit-line-clamp: 3;
  }
  &--4{
    -webkit-line-clamp: 4;
  }
  &--5{
    -webkit-line-clamp: 5;
  }
}
.w-40{
  width: 40%;
}

.btn-orange {
  background: #ff8235 !important;
  color: #fff !important;
  border-color: #ff8235 !important;
}

.btn-outline-orange {
  color: #ff8235 !important;
  border-color: #ff8235 !important;
  background: #fff !important;

  &:hover {
    color: #000 !important;
    background: #ff8235 !important;
  }
}

.bg-orange {
  background: #ff8235;
}

.pendency-badge {
  width: fit-content;
  padding: .2rem .5rem;
  border-radius: .3rem;
  color: #fff !important;
}
