.process-accordian {
  .accordion-item {
    border-radius: 0px !important;
    border-left: 0px;
    border-right: 0px;
  }

  .accordion-button {
    border-radius: 0px !important;
    padding-left: 0px;
    padding-right: 0px;
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 24px;

    &:focus {
      box-shadow: none;
    }

    &:not(.collapsed) {
      background-color: transparent;
      box-shadow: none;
    }

    &:after {
      background-image: url('data:image/svg+xml,%3Csvg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9934 9.55422 24.6216 6.25145 22.1851 3.81491C19.7486 1.37837 16.4458 0.00660747 13 0V0ZM18.2375 12.175L13.7375 17.175C13.6438 17.2773 13.5299 17.359 13.4029 17.4149C13.2759 17.4708 13.1387 17.4997 13 17.4997C12.8613 17.4997 12.7241 17.4708 12.5971 17.4149C12.4701 17.359 12.3562 17.2773 12.2625 17.175L7.76251 12.175C7.59019 11.9783 7.50175 11.722 7.51614 11.461C7.53052 11.1999 7.64659 10.9548 7.83947 10.7783C8.03234 10.6018 8.28669 10.5078 8.54801 10.5165C8.80933 10.5253 9.05684 10.636 9.23751 10.825L13 15L16.7625 10.825C16.8503 10.7249 16.9571 10.6433 17.0768 10.585C17.1965 10.5267 17.3267 10.4929 17.4596 10.4855C17.5925 10.4782 17.7256 10.4975 17.851 10.5423C17.9764 10.5871 18.0916 10.6565 18.1898 10.7464C18.288 10.8362 18.3673 10.9449 18.423 11.0658C18.4786 11.1867 18.5096 11.3176 18.5141 11.4506C18.5185 11.5837 18.4963 11.7163 18.4488 11.8407C18.4014 11.9651 18.3295 12.0788 18.2375 12.175Z" fill="%23191919"/%3E%3C/svg%3E');
    }
  }

  .accordion-body {
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 40px;
    color: #a6a6a6;
  }
}

.committee-accordian {
  .accordion-item {
    border-left: 0px;
    border-right: 0px;
    border: none;
    margin-top: 10px;
  }

  .accordion-button {
    font-weight: 600;
    padding-top: 22px;
    padding-bottom: 22px;
    font-size: 16px;
    background-color: #f7f7f7;
    border-radius: 6px !important;

    &:focus {
      box-shadow: none;
    }

    &:after {
      background-image: url('data:image/svg+xml,%3Csvg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9934 9.55422 24.6216 6.25145 22.1851 3.81491C19.7486 1.37837 16.4458 0.00660747 13 0ZM18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14Z" fill="%23191919"/%3E%3C/svg%3E');
    }

    &:not(.collapsed) {
      box-shadow: none;
      background-color: transparent;
      &:after {
        background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="%23000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M8 12H16" stroke="%23000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A');
      }
    }
  }

  .accordion-body {
    padding-top: 0px;
    padding-bottom: 40px;
    color: #a6a6a6;
  }
}

.who-accordian {
  .accordion-item {
    border-left: 0px;
    border-right: 0px;
    margin-top: 10px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px !important;
    border-color: #f7f7f7;
  }

  .accordion-button {
    font-weight: 600;
    padding-top: 22px;
    padding-bottom: 22px;
    font-size: 16px;
    background-color: #f7f7f7;
    border-radius: 6px !important;

    &:focus {
      box-shadow: none;
    }

    &:after {
      background-image: url('data:image/svg+xml,%3Csvg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9934 9.55422 24.6216 6.25145 22.1851 3.81491C19.7486 1.37837 16.4458 0.00660747 13 0ZM18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14Z" fill="%23191919"/%3E%3C/svg%3E');
    }

    &:not(.collapsed) {
      box-shadow: none;
      background-color: transparent;
      &:after {
        background-image: url('data:image/svg+xml,%3Csvg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cg clip-path="url(%23clip0_6_6)"%3E%3Cpath d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.00563294 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9934 9.55422 24.6216 6.25145 22.1851 3.81491C19.7486 1.37837 16.4458 0.00660747 13 0Z" fill="%23191919"/%3E%3Cpath d="M8 13H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="clip0_6_6"%3E%3Crect width="26" height="26" fill="white"/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A');
      }
    }
  }

  .accordion-body {
    color: #a6a6a6;
  }
}

.faq-accordian {
  .accordion-item {
    border-radius: 0px !important;
    border-left: 0px;
    border-right: 0px;
    border-color: #ededed;
  }

  .accordion-button {
    border-radius: 0px !important;
    padding-left: 0px;
    padding-right: 0px;
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    border-color: #ededed;

    &:focus {
      box-shadow: none;
    }

    &:not(.collapsed) {
      background-color: transparent;
      box-shadow: none;
    }

    &:after {
      background-image: url('data:image/svg+xml,%3Csvg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9934 9.55422 24.6216 6.25145 22.1851 3.81491C19.7486 1.37837 16.4458 0.00660747 13 0ZM18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14Z" fill="%23191919"/%3E%3C/svg%3E');
    }

    &:not(.collapsed) {
      &:after {
        background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="%2308950F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M8 12H16" stroke="%2308950F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A');
      }
    }
  }

  .accordion-body {
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 40px;
    color: #a6a6a6;
  }
}

.proposal-history-accordian{
  .accordion-item{
    border-radius: 0.25rem;
    overflow: hidden;
    padding: 2px;
    
    &+.accordion-item{
      margin-top: 5px;
    }
    &:not(:first-of-type){
      border:1px solid #ccc !important;
    }
    .accordion-button {
      box-shadow: none;
      padding:0.5rem 1rem;
      border-radius: 0.25rem;
      overflow: hidden;
      &:after {
        background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="%23191919" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"%3E%3C/path%3E%3Cpath d="M12 8V16" stroke="%23191919" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"%3E%3C/path%3E%3Cpath d="M8 12H16" stroke="%23191919" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"%3E%3C/path%3E%3C/svg%3E');
      }
  
      &:not(.collapsed) {
        &:after {
          background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="%2308950F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M8 12H16" stroke="%2308950F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A');
        }
      }
    }

    .accordion-body {
      padding-bottom: 0.25rem !important;
      padding-top: 0.5rem !important;

    }

    .panel-heading-inner{
      @media screen and (min-width:768px) {
        max-width: calc(100% - 20px);
        & > .row{
          & > div:first-child{
            max-width: calc(100% - 175px);
            flex: 0 0 calc(100% - 175px);
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.proposal-summary-accordion{
  .accordion-button{
    padding-top: 10px;
    padding-bottom: 10px;
    background: transparent !important;
    &:after{
      display: none;
    }
  }
}