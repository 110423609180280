.proposal-print {
  padding: 25px;
  .common-nav-tabs,
  .caf-form-actions,
  .stepper-wrapper {
    display: none !important;
  }
}

@media print {

  html,
  body {
    page-break-after: avoid;
    page-break-before: avoid;
  }

  * {
    page-break-inside: avoid;
  }

  .card {
    border: none;
    margin-bottom: 0px !important;
  }
  .proposal-print,
  .card-body {
    padding: 0px !important;
  }
  .ps-3{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .common-nav-tabs,
  .caf-form-actions,
  .print-d-none {
    display: none !important;
  }

  .main-sidebar+.main-content {
    padding-left: 0px;
  }

  .proposal-print .common-nav-tabs,
  .proposal-print .caf-form-actions,
  .proposal-print .stepper-wrapper {
    display: none;
  }

  .common-nav-tabs,
  .caf-form-actions,
  .stepper-wrapper,
  .btn-print {
    display: none !important;
  }

  .form-control:disabled,
  .form-control[disabled],
  .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
}