.schedule-agenda {
  $agenda-color: $warning;
  $agenda-text-color: #c4c4c4;
  $agenda-padding: 8px;
  display: inline-block;
  border-left: 3px solid $agenda-color;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 0px 5px 5px 0px;
  color: $agenda-text-color;
  padding: $agenda-padding;
  font-size: 14px;
}
