.common-nav-tabs {
  margin-bottom: 20px;
  .nav-link {
    border-bottom: 2px solid #e8fde9;
    color: #c4c4c4;
    .step-count{
      border-radius: 50%;
      background-color: #f5f5f5;
      color: #c4c4c4;
      padding: 3px;
      width: 20px;
      height: 20px;
      line-height:normal;
      margin-right: 10px;
      font-size: 12px;
    }
    &.active {
      position: relative;
      color: $primary;
      &::after {
        content: "";
        position: absolute;
        left: 0px;
        top: 100%;
        height: 2px;
        width: 100%;
        background-color: $primary;
        z-index: 1;
      }
      .step-count{
        color: $primary;
        background-color: rgba($primary, 0.1);
      }
    }
  }
}

.nav-tab {
  $green: #08950f;
  padding: 16px 24px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  .nav-link {
    margin: 5px 10px;
    &:hover {
      background-color: #f7f7f7;
    }
    &.active {
      color: $green;
      background: rgba($green, 0.1);
    }
  }
}

.nav-tabs {
  $green: #08950f;
  $color: #a6a6a6;
  padding: 16px 24px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  .nav-link {
    margin: 5px 10px;
    display: inline-flex;
    color: $color;
    justify-content: center;

    &:hover {
      background-color: #f7f7f7;
    }
    &.active {
      color: $white;
      background: $green;
    }
  }
}

.nav-contact {
  margin-top: 15px;
  background: $white;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 10px;

  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 130px;
  }

  .nav-item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .nav-link {
    background-color: #f6f6f6;
    color: $black;
    border-radius: 4px;
    background-image: url('data:image/svg+xml,%3Csvg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M6.39931 4.87982L1.80931 0.289816C1.62195 0.103565 1.3685 -0.000976562 1.10431 -0.000976562C0.840128 -0.000976562 0.586676 0.103565 0.399314 0.289816C0.305586 0.382779 0.231191 0.49338 0.180423 0.615239C0.129654 0.737098 0.103516 0.867804 0.103516 0.999816C0.103516 1.13183 0.129654 1.26253 0.180423 1.38439C0.231191 1.50625 0.305586 1.61685 0.399314 1.70982L4.99931 6.28982C5.09304 6.38278 5.16744 6.49338 5.21821 6.61524C5.26897 6.7371 5.29511 6.86781 5.29511 6.99982C5.29511 7.13183 5.26897 7.26253 5.21821 7.38439C5.16744 7.50625 5.09304 7.61685 4.99931 7.70982L0.399314 12.2898C0.21101 12.4768 0.104696 12.7309 0.103758 12.9963C0.10282 13.2616 0.207337 13.5165 0.394315 13.7048C0.581292 13.8931 0.835415 13.9994 1.10078 14.0004C1.36614 14.0013 1.62101 13.8968 1.80931 13.7098L6.39931 9.11982C6.96112 8.55732 7.27668 7.79482 7.27668 6.99982C7.27668 6.20481 6.96112 5.44232 6.39931 4.87982Z" fill="%23191919"/%3E%3C/svg%3E%0A');
    background-repeat: no-repeat;
    background-position: right 10px center;
    padding-right: 30px;
    &.active {
      background-image: url('data:image/svg+xml,%3Csvg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M6.39931 4.87982L1.80931 0.289816C1.62195 0.103565 1.3685 -0.000976562 1.10431 -0.000976562C0.840128 -0.000976562 0.586676 0.103565 0.399314 0.289816C0.305586 0.382779 0.231191 0.49338 0.180423 0.615239C0.129654 0.737098 0.103516 0.867804 0.103516 0.999816C0.103516 1.13183 0.129654 1.26253 0.180423 1.38439C0.231191 1.50625 0.305586 1.61685 0.399314 1.70982L4.99931 6.28982C5.09304 6.38278 5.16744 6.49338 5.21821 6.61524C5.26897 6.7371 5.29511 6.86781 5.29511 6.99982C5.29511 7.13183 5.26897 7.26253 5.21821 7.38439C5.16744 7.50625 5.09304 7.61685 4.99931 7.70982L0.399314 12.2898C0.21101 12.4768 0.104696 12.7309 0.103758 12.9963C0.10282 13.2616 0.207337 13.5165 0.394315 13.7048C0.581292 13.8931 0.835415 13.9994 1.10078 14.0004C1.36614 14.0013 1.62101 13.8968 1.80931 13.7098L6.39931 9.11982C6.96112 8.55732 7.27668 7.79482 7.27668 6.99982C7.27668 6.20481 6.96112 5.44232 6.39931 4.87982Z" fill="%23ffffff"/%3E%3C/svg%3E%0A');
    }
  }
}
