.mat-stepper-horizontal-line.ng-tns-c120-1.ng-star-inserted {
  margin: 0;
  min-width: 0;
  position: initial;
}
.mat-horizontal-stepper-header-container {
  white-space: nowrap;
  display: flex;
  align-items: baseline !important;
  margin-left: 30%;
  margin-right: 30%;
  margin-bottom: 1.5%;
}
@media only screen and (max-width: 400px) {
  .mat-horizontal-stepper-header-container {
    white-space: nowrap;
    display: flex;
    align-items: baseline !important;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 1.5%;
  }
}
.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: hsl(0deg 0% 0% / 0%);
}

.mat-stepper-horizontal-line {
  position: initial;
  border-top-width: 0;
  border-top-style: solid;
  flex: auto;
  height: 0;
  border-width: 4px !important;
  border-color: #08950f;
  padding-bottom: 3vh;
  margin: 0px -53px 0 -39px !important;
}
.mat-step-header .mat-step-icon-selected {
  width: 40px;
  height: 40px;
  background-color: #08950f !important;
  color: white;

  .mat-step-icon-content {
    color: rgb(255, 255, 255);
  }
}

.mat-step-header .mat-step-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: #ffffff;
  transition: 0.4s;
  border: #08950f 4px solid;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  position: initial !important;
}

.mat-step-icon-content {
  color: black;
}

.mat-step-header.cdk-program-focused {
  background-color: transparent;
}
.mat-step-header.cdk-mouse-focused:hover {
  background-color: transparent;
}
.mat-step-header.cdk-focused:hover {
  background-color: transparent;
}

.mat-step-header.cdk-program-focused:hover {
  background-color: transparent;
}
.mat-step-header.cdk-focused:hover {
  background-color: transparent;
}
.mat-step-header:hover {
  background-color: transparent !important;
}
