// Environment Clearance

.section-about {
  $top-space: 126px;
  padding-top: $top-space;
  font-family: "Lato";
  font-weight: 400;

  .nav-tab {
    margin-top: -($top-space + 40px);
    position: relative;
    z-index: 1;
    background-color: $white;
  }

  .section-inner {
    background: $white;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px 0px;
  }
}

.section-intro {
  font-family: "Lato";
  font-weight: 400;

  .section-inner {
    background: $white;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0px;
  }
}

.stats-card {
  $applied: #6a0dad;
  $sectors: #1f00dd;
  $projects: #ffbd00;
  $approved: #08950f;

  background: #ffffff;
  border: 1px solid #f1e7f7;
  box-shadow: 0px 0px 20px rgba(106, 13, 173, 0.03);
  border-radius: 10px;
  padding: 30px;
  line-height: normal;

  .stats-icon {
    width: 60px;
    height: 60px;
    background: $applied;
    border-radius: 900px;
    box-shadow: 0px 0px 0px 5px rgba($applied, 0.1);
  }

  .stats-count {
    font-size: 40px;
    color: $applied;
    font-weight: 700;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0px;
      bottom: 0px;
      display: inline-block;
      height: 2px;
      width: 100%;
      background-color: $applied;
      border-radius: 10px;
    }
  }

  .stats-name {
    font-size: 20px;
    font-weight: 600;
  }

  &.sectors {
    .stats-icon {
      background: $sectors;
      box-shadow: 0px 0px 0px 5px rgba($sectors, 0.1);
    }

    .stats-count {
      color: $sectors;

      &:after {
        background-color: $sectors;
      }
    }
  }

  &.projects {
    .stats-icon {
      background: $projects;
      box-shadow: 0px 0px 0px 5px rgba($projects, 0.1);
    }

    .stats-count {
      color: $projects;

      &:after {
        background-color: $projects;
      }
    }
  }

  &.approved {
    .stats-icon {
      background: $approved;
      box-shadow: 0px 0px 0px 5px rgba($approved, 0.1);
    }

    .stats-count {
      color: $approved;

      &:after {
        background-color: $approved;
      }
    }
  }
}

.section-why-environment-clearance {
  background: $white;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 0px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  }

  .img-square {
    padding: 20px;

    &:before {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100px;
      height: 100px;
      background: #fff9e6;
      border-radius: 10px;
      z-index: 1;
    }

    img {
      position: relative;
      z-index: 2;
    }
  }
}

.section-process-we-follow,
.section-rules,
.section-eac-support,
.section-faq,
.section-notifications,
.section-manuals,
.section-cms {
  padding: 80px 0px;
}

.section-committee {
  padding: 40px 0px;
}

.section-faq {
  .faq-card {
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    padding: 74px 50px;
  }
}

.section-eac-support,
.section-rules,
.section-notifications,
.section-manuals,
.section-cms {
  .support-list {
    padding: 0px 30px;
    max-height: 320px;
    overflow-y: auto;
    & > .row {
      margin-top: 16px;
    }

    .dots {
      position: relative;
      padding-left: 20px;

      &:before {
        content: "";
        position: absolute;
        left: 5px;
        top: 7px;
        width: 8px;
        height: 8px;
        background-color: $black;
        border-radius: 50%;
      }
    }

    .btn.btn-link {
      text-decoration: none;
      min-width: 120px;
    }
  }
}

// FAQ Page
.raise-query-info {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 12px 48px;
  margin-top: 48px;
  font-size: 18px;
}

.faq-search-list {
  margin-top: 80px;

  .faq-search-item {
    background: $white;
    border: 2px solid #a6a6a6;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 34px 40px;

    .faq-title {
      margin-bottom: 24px;
      color: $primary;
    }
  }
}

/* notification and anouncement*/
.notification-box {
  background: $white;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 4px;
  margin: 10px;
  height: calc(100% - 20px);

  .card-body {
    position: relative;
    padding-bottom: 70px;
    height: 100%;

    .notification-action {
      position: absolute;
      right: 15px;
      bottom: 15px;
    }
  }

  .notification-content {
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .notification-more {
    padding: 8px 20px;
    border: 2px solid $primary;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: $primary;
    display: inline-block;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: $white;
      background-color: $primary;
    }
  }
}

/* Event Box */
.events-box {
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 4px;
  background: $white;

  .events-image {
    position: relative;

    img {
      width: 100%;
    }
  }

  .events-content {
    position: absolute;
    bottom: 0px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 0px 16px;
    color: $white;
    z-index: 2;
  }

  .event-image-shadow {
    position: absolute;
    bottom: 0px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 21.18%,
      rgba(0, 0, 0, 0.7) 57.64%,
      #000000 100%
    );
    z-index: 1;
    width: 100%;
    height: 60%;
  }

  .events-more {
    padding: 10px 20px;
    background: $primary;
    text-align: center;
    color: $white;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;

    &:hover {
      color: rgb(208, 252, 228);
    }
  }
}

/* Object Parivesh */
.section-objectives,
.section-feature {
  padding: 80px 0px;
}
.objective-item {
  margin-top: 20px;
  margin-bottom: 20px;
}
.objective-card {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  transition: all 600ms ease;

  &:before {
    content: "";
    padding-top: 125%;
    display: block;
  }

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    left: 0px;
    top: 0px;
    transition: all 600ms ease;
  }

  .objective-card-info {
    $space: 15px;
    position: absolute;
    bottom: 0px;
    left: $space;
    right: $space;
    margin: $space auto;
    padding: 16px;
    z-index: 2;
    color: $white;
    border-radius: 4px;
    overflow: hidden;
  }

  .objective-card-info:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    bottom: 0px;
    z-index: -1;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(12px);
  }

  .objective-card-title {
    padding-right: 30px;
    background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M14.3105 8L20.0505 17.94" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M9.68945 8H21.1695" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M7.38086 12.0001L13.1209 2.06006" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M9.68922 16.0001L3.94922 6.06006" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M14.3101 16H2.83008" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M16.6209 12L10.8809 21.94" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A');
    background-position: right center;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    transition: all 600ms ease;
  }

  .objective-desc {
    max-height: 0px;
    opacity: 0;
    overflow: auto;
    font-size: 12px;
    transition: all 600ms ease;
  }

  &:hover {
    transform: scale(1, 1.1);
    .objective-desc {
      opacity: 1;
      max-height: 400px;
    }
    img {
      transform: scale(1.1, 1);
    }
    .objective-card-title,
    .objective-desc {
      transform: scale(1, 0.9);
    }
  }
}

.features {
  margin-top: 50px;
  .feature-item {
    &:not(:last-child) {
      margin-right: 35px;
      margin-left: 35px;
    }
    .feature-title {
      max-width: 180px;
    }
    white-space: normal;
    .feature-icon {
      width: 48px;
      height: 48px;
      border-radius: 4px;
      color: $white;
    }

    &:nth-child(4n-3) {
      .feature-icon {
        background: $primary;
        box-shadow: 0px 4px 20px rgba(0, 255, 240, 0.25);
      }
    }

    &:nth-child(4n-1) {
      .feature-icon {
        background: $warning;
        box-shadow: 0px 4px 20px rgba(38, 140, 238, 0.25);
      }
    }
  }
  .feature-divider {
    padding-top: 15px;
    &:nth-child(4n) {
      transform: scaleX(-1);
    }
  }
}


/* Analytis Dashboard */
.statistic{
  &-card{
      position: relative;
      color: #fff;
      padding: 20px 15px;
      border-radius: 3px;
      overflow: hidden;
      background: linear-gradient(135deg, #23bdb8 0, #65a986 100%) !important;
      height: 100%;
      &--show-info{
          padding: 20px 15px 50px 15px;
      }
  }
  &__title{
    font-size: 14px;
  }
  &__icon{
      display: block;
      background: rgba($color: #fff, $alpha: 0.2);
      padding: 15px;
      border-radius: 50%;
      width: 64px;
      height: 64px;
  }
  &__count{
    font-size: 20px;
  }
  &__get-info{
      position: absolute;
      background: rgba($color: #000, $alpha: 0.2);;
      width: 100%;
      left: 0px;
      text-align: center;
      bottom: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 8px 0px 15px;
  }
}
.bg-green-dark {
  background: linear-gradient(135deg, #23bdb8 0, #65a986 100%) !important;
}
.bg-cyan-dark {
  background: linear-gradient(135deg, #289cf5, #4f8bb7) !important;
}
.bg-purple-dark {
  background: linear-gradient(135deg, #8e4cf1 0, #c554bc 100%) !important;
}
.bg-orange-dark {
  background: linear-gradient(135deg, #f48665 0, #d68e41 100%) !important;
}

/* editor Full Screen */
.fullscreen {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1020;
  background: #fff;
  width: 100%;
  height: 100vh;
  .ck-editor__editable{
    height: 90vh !important;
    max-height: 90vh !important;
  }
}
.btn-fullscreen{
  position: absolute !important;
  right: 0px;
  top: 0px;
  z-index: 9 !important;
}