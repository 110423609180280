/* HEADER */
.header {
  border-bottom: 2px solid #c4c4c4;
  background-color: #fff;
  position: sticky;
  z-index: 10;
  top: 0px;
  .header-top-bar {
    background-color: #ededed;
    padding: 5px 0px;
  }
  .header-logo {
    padding: 5px 0px;
  }
  .header-top-buttons {
    font-size: 14px;
    .divider {
      border-right: 2px solid #191919;
      margin-left: 6px;
      margin-right: 6px;
      @include media-breakpoint-up(md) {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
    .btn-font-size {
      font-size: inherit;
      font-weight: 700;
      margin-left: 6px;
      margin-right: 6px;
    }
    .dropdown-toggle {
      font-size: inherit;
      font-weight: 700;
    }
  }
}

/* NAVBAR */
nav.navbar {
  .nav-link {
    font-style: normal;
    font-family: "Lato";
    font-weight: 700;
    font-size: 16px;
  }
  @include media-breakpoint-up(lg) {
    .navbar-nav + .navbar-nav {
      .nav-item + .nav-item {
        margin-left: 10px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .register-btn,
    .login-btn {
      display: inherit;
      margin: 10px 0px 0px 0px;
    }
  }
}
.navbar-light {
  .navbar-nav {
    .nav-link {
      color: #191919;
      margin-right: 10px;
      &:hover {
        color: $primary;
      }
    }
  }
}

/* Menu Action Button */
.register-btn {
  background-color: #08950f;
  border: none;
  color: #fff;
  height: 44px;
  text-align: center;
  padding: 8px 15px;
  cursor: pointer;
  margin-top: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  &:hover {
    color: rgb(206, 206, 206);
  }
}

.login-btn {
  background-color: #979797;
  border: none;
  color: #fff;
  height: 44px;
  cursor: pointer;
  text-align: center;
  padding: 8px 15px;
  margin-top: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
}

/* FOOTER */
.footer {
  position: relative;
  width: auto;
  color: $white;
  background: #22242a;
  padding: 60px 15px 0px 15px;
  @include media-breakpoint-down(xl) {
    padding: 40px 15px 0px 15px;
  }
  .footer-logo {
    @include media-breakpoint-up(lg) {
      margin-right: 50px;
    }
    @include media-breakpoint-down(lg) {
      margin-bottom: 30px;
    }
  }
  .footer-link {
    font-size: 16px;
    color: $white;
    text-decoration: none;
    @include media-breakpoint-down(xl) {
      font-size: 14px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
    &:not(:last-child) {
      margin-right: 40px;
      @include media-breakpoint-down(xl) {
        margin-right: 20px;
      }
      @include media-breakpoint-down(sm) {
        margin-right: 15px;
      }
    }
  }
  .footer-info-links {
    border-top: 1px solid $white;
    padding-top: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    .footer-link {
      font-size: 14px;
      @include media-breakpoint-down(xl) {
        font-size: 13px;
      }
      @include media-breakpoint-down(md) {
        font-size: 12px;
        display: inline-block;
        width: 50%;
        margin-right: 0px;
        margin-bottom: 10px;
        &:nth-child(odd) {
          padding-right: 15px;
        }
      }
    }
    @include media-breakpoint-down(xl) {
      padding-top: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .social-medias {
    .social-media-link {
      &:not(:last-child) {
        margin-right: 20px;
      }
      @include media-breakpoint-down(xl) {
        img {
          height: 20px;
        }
      }
    }
    @include media-breakpoint-down(md) {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid $white;
    }
  }
  .footer-end {
    padding: 4px 12px;
    max-width: 574px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    background: #3c3c3c;
    border-radius: 10px 10px 0px 0px;
  }
  .footer-last-update {
    background: #3c3c3c;
    border-radius: 4px;
    padding: 4px 8px;
    max-width: 270px;
    margin-bottom: 10px;
    text-align: center;
    @include media-breakpoint-up(xl) {
      position: absolute;
      right: 20px;
      bottom: 10px;
      max-width: 140px;
      text-align: left;
    }
  }
}

.total-visitors {
  display: inline-block;
  background: #3c3c3c;
  border: 1px solid $white;
  border-radius: 4px;
  padding: 7px 8px;
  .visitors-count {
    background: #888888;
    border-radius: 4px;
    padding: 2px 4px;
    margin-left: 10px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 20px;
  }
}

/* Main Nav Menu */
nav.navbar {
  position: sticky;
  top: 45px;
  z-index: 10;
  @include media-breakpoint-down(lg) {
    &.navbar-light .nav-link {
      margin-top: 10px;
      border: 1px solid #ccc;
      text-align: center;
      margin-right: 0px;
    }
  }
  @include media-breakpoint-down(sm) {
    top: 62px;
  }
}

/* Sidebar Menu  */
$sidebar-width: 300px;
$sidebar-width-sm: 80px;
$sidebar-bg: #ffffff;
.main-sidebar {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  background-color: $sidebar-bg;
  width: $sidebar-width-sm;
  height: 100vh;
  padding: 130px 20px 20px 20px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 200ms ease;
  & + .main-content {
    padding-left: $sidebar-width-sm;
  }
}
.main-content {
  .main-content-inner {
    padding: 25px 30px;
    min-height: 100vh;
    background: $card-bg;
  }
}

/* Sidebar Menu */
.sidebar-menu {
  .sidebar-menu-header {
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
  .sidebar-menu-footer {
    border-top: 1px solid #c4c4c4;
    padding-top: 10px;
    margin-top: 30px;
  }
  .logo-lg {
    display: none;
  }
  .logo-sm {
    display: inline-block;
  }
  .sidebar-menu-text {
    display: none;
  }
  .sidebar-menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-decoration: none;
    color: #191919;
    border-radius: 8px;
    .sidebar-menu-icon {
      padding: 12px;
      width: 44px;
      height: 44px;
      border-radius: 8px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &:hover,
    &.logout {
      background: #ededed;
    }
    &.active {
      color: #08950f;
      background: #e8fde9;
      .sidebar-menu-icon {
        background: #e8fde9;
      }
    }
  }
  .sidebar-menu-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    margin-bottom: 10px;
    font-weight: 500;
    .sidebar-menu-icon {
      width: 44px;
      height: 44px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .text-muted {
      font-size: 12px;
      font-weight: normal;
      display: block;
    }
    .sidebar-menu-profile-pic {
      display: block;
      width: 24px;
      height: 24px;
      object-fit: cover;
      border-radius: 50%;
      margin: 0px auto;
    }
  }
}

.main-sidebar {
  &:hover {
    width: $sidebar-width;
    .logo-lg {
      display: inline-block;
    }
    .logo-sm {
      display: none;
    }
    .sidebar-menu-text {
      display: block;
    }
    .sidebar-menu-item,
    .sidebar-menu-profile {
      justify-content: flex-start;
    }
  }
}

/* Main Heading */
.main-heading {
  font-family: "Lora", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  color: #ffbd00;
}

.subheading {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  font-family: "Lato";
  color: #191919;
  position: relative;
  display: inline-block;
  margin-bottom: 24px;
  padding-right: 90px;
  .hrline {
    width: 73px;
    height: 2px;
    background: #191919;
    position: absolute;
    top: 15px;
    right: 0px;
  }
}

.page-heading {
  font-size: 32px;
  font-weight: 700;
}

.page-section-heading {
  // text-align: center;
  // font-weight: 600;
}

.content-wrapper {
  // max-width: 1200px;
  margin: 0px auto;
}

.page-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: rgba($white, 0.5);
  .spinner-border {
    width: 5rem;
    height: 5rem;
  }
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.modi-modal-popup {
  .btn-modi-modal-close {
    top: -28px;
    right: -28px;
    outline: none;
    box-shadow: none;
  }
}

// Hero Banner Naviagtion
.hero-banner {
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 64px;
    height: 64px;
  }
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAiIGhlaWdodD0iODAiIHZpZXdCb3g9IjAgMCA4MCA4MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfaV8xXzE2KSI+CjxwYXRoIGQ9Ik0wIDBIODBWODBIMFYwWiIgZmlsbD0iI0M0QzRDNCIgZmlsbC1vcGFjaXR5PSIwLjA1Ii8+CjxwYXRoIGQ9Ik0xIDFINzlWNzlIMVYxWiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIi8+CjwvZz4KPHBhdGggZD0iTTQwIDU1QzMxLjcxNTcgNTUgMjUgNDguMjg0MyAyNSA0MEMyNSAzMS43MTU3IDMxLjcxNTcgMjUgNDAgMjVDNDguMjg0MyAyNSA1NSAzMS43MTU3IDU1IDQwQzU1IDQ4LjI4NDMgNDguMjg0MyA1NSA0MCA1NVoiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMy4zMzMzMyIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+CjxwYXRoIGQ9Ik0zOS4wNTI3IDQ1LjMwMzhMMzMuNzQ5NSA0MC4wMDA1TDM5LjA1MjcgMzQuNjk3MyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzLjMzMzMzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTQ2LjI1IDQwSDMzLjc1IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjMuMzMzMzMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9pXzFfMTYiIHg9Ii0xMCIgeT0iMCIgd2lkdGg9IjkwIiBoZWlnaHQ9IjgwIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9InNoYXBlIi8+CjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIiByZXN1bHQ9ImhhcmRBbHBoYSIvPgo8ZmVPZmZzZXQgZHg9Ii0xMCIvPgo8ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIxNSIvPgo8ZmVDb21wb3NpdGUgaW4yPSJoYXJkQWxwaGEiIG9wZXJhdG9yPSJhcml0aG1ldGljIiBrMj0iLTEiIGszPSIxIi8+CjxmZUNvbG9yTWF0cml4IHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwLjQgMCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0ic2hhcGUiIHJlc3VsdD0iZWZmZWN0MV9pbm5lclNoYWRvd18xXzE2Ii8+CjwvZmlsdGVyPgo8L2RlZnM+Cjwvc3ZnPgo=");
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAiIGhlaWdodD0iODAiIHZpZXdCb3g9IjAgMCA4MCA4MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfaV8xXzE3KSI+CjxwYXRoIGQ9Ik04MCAwSDBWODBIODBWMFoiIGZpbGw9IiNDNEM0QzQiIGZpbGwtb3BhY2l0eT0iMC4wNSIvPgo8cGF0aCBkPSJNNzkgMUgxVjc5SDc5VjFaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9nPgo8cGF0aCBkPSJNNDAgNTVDNDguMjg0MyA1NSA1NSA0OC4yODQzIDU1IDQwQzU1IDMxLjcxNTcgNDguMjg0MyAyNSA0MCAyNUMzMS43MTU3IDI1IDI1IDMxLjcxNTcgMjUgNDBDMjUgNDguMjg0MyAzMS43MTU3IDU1IDQwIDU1WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzLjMzMzMzIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPHBhdGggZD0iTTQwLjk0NzMgNDUuMzAzOEw0Ni4yNTA1IDQwLjAwMDVMNDAuOTQ3MyAzNC42OTczIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjMuMzMzMzMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMzMuNzUgNDBINDYuMjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMy4zMzMzMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2lfMV8xNyIgeD0iLTEwIiB5PSIwIiB3aWR0aD0iOTAiIGhlaWdodD0iODAiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj4KPGZlRmxvb2QgZmxvb2Qtb3BhY2l0eT0iMCIgcmVzdWx0PSJCYWNrZ3JvdW5kSW1hZ2VGaXgiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0ic2hhcGUiLz4KPGZlQ29sb3JNYXRyaXggaW49IlNvdXJjZUFscGhhIiB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMTI3IDAiIHJlc3VsdD0iaGFyZEFscGhhIi8+CjxmZU9mZnNldCBkeD0iLTEwIi8+CjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjE1Ii8+CjxmZUNvbXBvc2l0ZSBpbjI9ImhhcmRBbHBoYSIgb3BlcmF0b3I9ImFyaXRobWV0aWMiIGsyPSItMSIgazM9IjEiLz4KPGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAuNCAwIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW4yPSJzaGFwZSIgcmVzdWx0PSJlZmZlY3QxX2lubmVyU2hhZG93XzFfMTciLz4KPC9maWx0ZXI+CjwvZGVmcz4KPC9zdmc+Cg==");
  }
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 64px;
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.owl-carousel {
  .owl-stage {
    display: flex;
    flex: 1 0 auto;
    height: 100%;
  }
  .owl-nav {
    @include media-breakpoint-up(xl) {
      margin-top: 0px;
    }
    .owl-next {
      right: 0;
      margin-right: -64px;
      font-size: 25px !important;
      background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA0IiBoZWlnaHQ9IjEwNCIgdmlld0JveD0iMCAwIDEwNCAxMDQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGZpbHRlcj0idXJsKCNmaWx0ZXIwX2RfODI3OV8zNzg4NykiPgo8Y2lyY2xlIHI9IjMyIiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSA1MiA1MikiIGZpbGw9IndoaXRlIi8+CjwvZz4KPHBhdGggZD0iTTQzLjc1IDUySDYwLjI1IiBzdHJva2U9IiMwODk1MEYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik01My41IDQ1LjI1TDYwLjI1IDUyTDUzLjUgNTguNzUiIHN0cm9rZT0iIzA4OTUwRiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPGRlZnM+CjxmaWx0ZXIgaWQ9ImZpbHRlcjBfZF84Mjc5XzM3ODg3IiB4PSIwIiB5PSIwIiB3aWR0aD0iMTA0IiBoZWlnaHQ9IjEwNCIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIgcmVzdWx0PSJoYXJkQWxwaGEiLz4KPGZlT2Zmc2V0Lz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMTAiLz4KPGZlQ29tcG9zaXRlIGluMj0iaGFyZEFscGhhIiBvcGVyYXRvcj0ib3V0Ii8+CjxmZUNvbG9yTWF0cml4IHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwLjEgMCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvd184Mjc5XzM3ODg3Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9kcm9wU2hhZG93XzgyNzlfMzc4ODciIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjwvZGVmcz4KPC9zdmc+Cg==");
      &:hover {
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA0IiBoZWlnaHQ9IjEwNCIgdmlld0JveD0iMCAwIDEwNCAxMDQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGZpbHRlcj0idXJsKCNmaWx0ZXIwX2RfODI3OV8zNzg4NykiPgo8Y2lyY2xlIHI9IjMyIiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSA1MiA1MikiIGZpbGw9IndoaXRlIi8+CjwvZz4KPHBhdGggZD0iTTQzLjc1IDUySDYwLjI1IiBzdHJva2U9IiMwODk1MEYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik01My41IDQ1LjI1TDYwLjI1IDUyTDUzLjUgNTguNzUiIHN0cm9rZT0iIzA4OTUwRiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPGRlZnM+CjxmaWx0ZXIgaWQ9ImZpbHRlcjBfZF84Mjc5XzM3ODg3IiB4PSIwIiB5PSIwIiB3aWR0aD0iMTA0IiBoZWlnaHQ9IjEwNCIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIgcmVzdWx0PSJoYXJkQWxwaGEiLz4KPGZlT2Zmc2V0Lz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMTAiLz4KPGZlQ29tcG9zaXRlIGluMj0iaGFyZEFscGhhIiBvcGVyYXRvcj0ib3V0Ii8+CjxmZUNvbG9yTWF0cml4IHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwLjEgMCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvd184Mjc5XzM3ODg3Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9kcm9wU2hhZG93XzgyNzlfMzc4ODciIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjwvZGVmcz4KPC9zdmc+Cg==");
      }
    }
    .owl-prev {
      left: 0;
      margin-left: -64px;
      font-size: 25px !important;
      background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA0IiBoZWlnaHQ9IjEwNCIgdmlld0JveD0iMCAwIDEwNCAxMDQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGZpbHRlcj0idXJsKCNmaWx0ZXIwX2RfODI3OV8zNzg4MSkiPgo8Y2lyY2xlIGN4PSI1MiIgY3k9IjUyIiByPSIzMiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8cGF0aCBkPSJNNjAuMjUgNTJINDMuNzUiIHN0cm9rZT0iIzA4OTUwRiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTUwLjUgNDUuMjVMNDMuNzUgNTJMNTAuNSA1OC43NSIgc3Ryb2tlPSIjMDg5NTBGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9kXzgyNzlfMzc4ODEiIHg9IjAiIHk9IjAiIHdpZHRoPSIxMDQiIGhlaWdodD0iMTA0IiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIiByZXN1bHQ9ImhhcmRBbHBoYSIvPgo8ZmVPZmZzZXQvPgo8ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIxMCIvPgo8ZmVDb21wb3NpdGUgaW4yPSJoYXJkQWxwaGEiIG9wZXJhdG9yPSJvdXQiLz4KPGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAuMSAwIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0iZWZmZWN0MV9kcm9wU2hhZG93XzgyNzlfMzc4ODEiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJlZmZlY3QxX2Ryb3BTaGFkb3dfODI3OV8zNzg4MSIgcmVzdWx0PSJzaGFwZSIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K");
      &:hover {
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA0IiBoZWlnaHQ9IjEwNCIgdmlld0JveD0iMCAwIDEwNCAxMDQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGZpbHRlcj0idXJsKCNmaWx0ZXIwX2RfODI3OV8zNzg4MSkiPgo8Y2lyY2xlIGN4PSI1MiIgY3k9IjUyIiByPSIzMiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8cGF0aCBkPSJNNjAuMjUgNTJINDMuNzUiIHN0cm9rZT0iIzA4OTUwRiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTUwLjUgNDUuMjVMNDMuNzUgNTJMNTAuNSA1OC43NSIgc3Ryb2tlPSIjMDg5NTBGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9kXzgyNzlfMzc4ODEiIHg9IjAiIHk9IjAiIHdpZHRoPSIxMDQiIGhlaWdodD0iMTA0IiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIiByZXN1bHQ9ImhhcmRBbHBoYSIvPgo8ZmVPZmZzZXQvPgo8ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIxMCIvPgo8ZmVDb21wb3NpdGUgaW4yPSJoYXJkQWxwaGEiIG9wZXJhdG9yPSJvdXQiLz4KPGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAuMSAwIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0iZWZmZWN0MV9kcm9wU2hhZG93XzgyNzlfMzc4ODEiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJlZmZlY3QxX2Ryb3BTaGFkb3dfODI3OV8zNzg4MSIgcmVzdWx0PSJzaGFwZSIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K");
      }
    }
    .owl-next,
    .owl-prev {
      width: 64px;
      height: 64px;
      background-position: center !important;
      background-size: contain !important;
      display: inline-flex;
    }
    @include media-breakpoint-up(xl) {
      .owl-next,
      .owl-prev {
        position: absolute;
        top: 48%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
      }
    }
  }
}

.hero-banner {
  .banner-shadow {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 17.43%,
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(270deg, rgba(0, 0, 0, 0) 43.06%, rgba(0, 0, 0, 0.4) 100%);
    z-index: 1;
  }
  .carousel-caption {
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    left: 0px;
    right: 0px;
    margin: 0 auto;
  }
  .btn-outline-warning {
    background: rgba(25, 25, 25, 0.8);
    &:hover {
      color: #000;
      background-color: #ffbd00;
      border-color: #ffbd00;
    }
  }
  .ratio {
    min-height: 380px;
    img {
      object-fit: cover;
    }
    @include media-breakpoint-up(md) {
      min-height: 520px;
      max-height: 642px;
    }
  }
  @include media-breakpoint-down(md) {
    .carousel-caption {
      position: relative;
      background: #7a7a7a;
      text-align: center;
      padding: 50px 30px;
    }
    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }
  }
  @include media-breakpoint-down(sm) {
    .btn {
      width: 100%;
    }
  }
}

.my-task-filter-dd {
  position: absolute;
  left: 105px;
  z-index: 1;
}

.my-all-proposals-filter-dd {
  position: absolute;
  left: 105px;
  z-index: 1;
  width: calc(100% - 25%);
}

.my-all-proposals-filter-year-change-dd {
  position: absolute;
  left: 105px;
  z-index: 1;
  width: calc(100% - 25%);
}

.eds-filter-dd {
  position: absolute;
  left: 5px;
  z-index: 1;
}

.select-all-checkbox {
  position: absolute;
  right: 43px;
  top: 88px;
  z-index: 1;
}

.h-35 {
  height: 35px;
}
// .select-all-checkbox-reject {
//   position: absolute;
//     right: 2.5%;
//     top: 16.5%;
//     z-index: 1;
// }
