.nav-proposal {
  $green: #08950f;
  $light-blue: #00f0ff;
  $light-green: #4fe300;
  $danger: #ff3232;
  $yellow: #ffbd00;
  padding: 16px 24px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  .nav-link {
    .nav-icon {
      margin-right: 10px;
    }
    &:hover {
      background-color: #f7f7f7;
    }
    &.green {
      &:hover {
        color: $green;
        background: rgba($green, 0.1);
      }
    }
    &.light-blue {
      &:hover {
        color: $light-blue;
        background: rgba($light-blue, 0.1);
      }
    }
    &.light-green {
      &:hover {
        color: $light-green;
        background: rgba($light-green, 0.1);
      }
    }
    &.danger {
      &:hover {
        color: $danger;
        background: rgba($danger, 0.1);
      }
    }
    &.yellow {
      &:hover {
        color: $yellow;
        background: rgba($yellow, 0.1);
      }
    }
  }
}

.btn-download-proposal {
  background: #e8fde9;
  border-radius: 4px;
  color: #08950f;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  span {
    display: inline-block;
    max-width: 100px;
    text-align: left;
    margin-right: 10px;
    line-height: normal;
  }
}

/* Show Divider in Dropdown */
.show-divider{
  .dropdown-item {
    position: relative;
    margin-top: 5px  !important;
    margin-bottom: 5px !important;
    &:after{
      content: '';
      position: absolute;
      width:100%;
      height: 1px;
      border-top: 1px solid #ececec;
      top: 100%;
      left: 0px;
      margin-top: 2px;
    }
    &:last-child{
      &::after{
        display: none;
      }
    }
  }
}